import React from 'react'

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="flex items-center justify-between flex-wrap px-6 py-3">
        
        {/* <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
          <div className="text-sm lg:flex-grow"></div>
            <Link to="/profile" className="block mt-4 lg:inline-block lg:mt-0 text-gray-700">
              Profile
            </Link>
        </div> */}
      </footer>
    )
  }
}

export default Footer

import React from 'react'
import { Link } from "gatsby"

const Header = class extends React.Component {
  // constructor(props) {
  //   super(props)
  // }

  render() {
    return (
      <div className="max-w-postpage m-auto">
        <nav className="flex items-center justify-start flex-wrap px-6 py-4">
          <div className="max-w-logo mr-6">
            <Link to="/" className="font-semibold text-xl text-black">
              {/* <img src="/img/logo.png" className="mb-0" /> */}
              Yoshinori Blog
            </Link>
          </div>
          <div className="flex items-center w-auto">
            <div className="text-sm"></div>
              <Link to="/about" className="inline-block text-gray-600 underline">
                About
              </Link>
          </div>
        </nav>
      </div>
    )
  }
}

export default Header
